import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';

import ApiService from '../../services/ApiService';
import { useAuth } from '../../context/auth';
import { Button } from 'primereact/button';

const api = new ApiService();

const MachineLocationDialog = ({
	dialogActive,
	setDialogActive,
	dialogType,
	toast,
	machineId,
	locationHistory,
	setLocationHistory,
}) => {
	const { authTokens } = useAuth();

	const [locations, setLocations] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState({});

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const listLocations = async () => {
			const locationResponse = await api.getAllLocations(headers);
			console.log(locationResponse.data);

			setLocations(locationResponse.data);
		};

		listLocations();

		return () => {
			setLocations([]);
		};
	}, [authTokens.token]);

	const handleLocationSubmit = async () => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		try {
			await api.updateMachineLocation(machineId, { locationId: selectedLocation.locationId }, headers);

			toast.current.show({
				severity: 'success',
				summary: 'Success',
				detail: 'Location updated',
				life: 3000,
			});

			// create a temporary location object for the new location
			const templocation = {
				endDate: null,
				locationId: selectedLocation.locationId,
				locationName: selectedLocation.name,
				machineId: machineId,
				startDate: Date.now(),
			};

			// update current location end date
			const updatedLocationHistory = locationHistory.map((location) => {
				if (!location.endDate) {
					return { ...location, endDate: Date.now() };
				}

				return location;
			});

			// sort the location history by start date
			const sortedLocationHistory = [...updatedLocationHistory, templocation].sort(
				(a, b) => new Date(b.startDate) - new Date(a.startDate),
			);

			setLocationHistory(sortedLocationHistory);
		} catch (error) {
			console.error('ERROR', error);

			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'Failed to update location',
				life: 3000,
			});
		} finally {
			setSelectedLocation({});
			setDialogActive(false);
		}
	};

	const onCancelLocationUpdate = () => {
		setSelectedLocation({});
		setDialogActive(false);

		toast.current.show({
			severity: 'info',
			summary: 'Info',
			detail: 'Location update cancelled',
			life: 3000,
		});
	};

	return (
		<Dialog
			header={`${dialogType} Location`}
			visible={dialogActive}
			style={{ width: '64rem' }}
			breakpoints={{ '960px': '75vw', '641px': '90vw' }}
			modal
			onHide={() => setDialogActive(false)}
			className="p-fluid"
		>
			<Message
				severity="warn"
				text="When changing the location, all new orders will be assigned to the new location. 
                    
                    Do not change the location until the machine is really installed in that location!"
				className="mb-4"
			/>

			<Dropdown
				value={selectedLocation}
				options={locations}
				onChange={(e) => setSelectedLocation(e.value)}
				optionLabel={(option) => option.name || option.fullAddress}
				placeholder="Select a location"
				filter
				className="w-100"
			/>

			<div className="flex justify-content-end mt-4">
				<div className="mr-2">
					<Button label="Cancel" icon="pi pi-times" onClick={onCancelLocationUpdate} severity="secondary" />
				</div>

				<div>
					<Button
						label="Save"
						icon="pi pi-check"
						onClick={handleLocationSubmit}
						disabled={!selectedLocation}
						severity="primary"
					/>
				</div>
			</div>
		</Dialog>
	);
};

export default MachineLocationDialog;
