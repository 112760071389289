import React, { useEffect, useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { ProgressSpinner } from 'primereact/progressspinner';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import MachineFilter from './machineFilter/MachineFilter';
import ActionBar from './machineActionBar/ActionBar';
import MachineDataTable from './machineDataTable/MachineDataTable';

import './Machine.css';

const api = new ApiService();

const Machine = () => {
	const [loading, setLoading] = useState(true);
	const [machines, setMachines] = useState([]);
	const [searchFilter, setSearchFilter] = useState('');
	const [typeFilter, setTypeFilter] = useState('');
	const [statusFilter, setStatusFilter] = useState('');
	const { authTokens } = useAuth();
	const [amountOfResults, setAmountOfResults] = useState();
	const [amountOfMachinesOffline, setAmountOfMachinesOffline] = useState(0);
	const [amountOfMachinesOnline, setAmountOfMachinesOnline] = useState(0);
	const [amountOfMachineSmoothie, setAmountOfMachineSmoothie] = useState(0);
	const [amountOfMachineSoup, setAmountOfMachineSoup] = useState(0);
	const [amountOfMachineCombo, setAmountOfMachineCombo] = useState(0);

	useEffect(() => {
		let loadingTimeout;

		try {
			const headers = {
				'X-Firebase-Auth-Client': authTokens.token,
			};
			const fetchAll = async () => {
				const response = await api.getMachines(headers);
				console.log(response.data);
				// sort on available for customer and alphabetically
				const machinesSorted = response.data.sort((a, b) => {
					if (a.availableForCustomer && !b.availableForCustomer) {
						return -1;
					} else if (!a.availableForCustomer && b.availableForCustomer) {
						return 1;
					} else {
						return a.name.localeCompare(b.name);
					}
				});

				setMachines(machinesSorted);
			};

			fetchAll();
		} catch (error) {
			console.error(error);
		} finally {
			loadingTimeout = setTimeout(() => {
				setLoading(false);
			}, 1000);
		}

		return () => {
			setMachines([]);
			clearTimeout(loadingTimeout);
		};
	}, [authTokens]);

	useEffect(() => {
		const initialCounts = {
			online: 0,
			offline: 0,
			smoothie: 0,
			soup: 0,
			combo: 0,
		};

		const counts = machines.reduce((acc, machine) => {
			if (machine.availableForCustomer) {
				acc.online++;
			} else {
				acc.offline++;
			}

			if (machine.type === 'SMOOTHIE') {
				acc.smoothie++;
			} else if (machine.type === 'SOUP') {
				acc.soup++;
			} else if (machine.type === 'COMBO') {
				acc.combo++;
			}

			return acc;
		}, initialCounts);

		setAmountOfMachinesOnline(counts.online);
		setAmountOfMachinesOffline(counts.offline);
		setAmountOfMachineSmoothie(counts.smoothie);
		setAmountOfMachineSoup(counts.soup);
		setAmountOfMachineCombo(counts.combo);
	}, [machines]);

	/**
	 * ----------------------------------------------------------------
	 * Filter machines based on search, type and status filters
	 * ----------------------------------------------------------------
	 */

	const filteredMachines = machines.filter((machine) => {
		const matchesSearchFilter =
			searchFilter === '' ||
			machine.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
			machine?.customerName?.toLowerCase().includes(searchFilter.toLowerCase());

		const matchesTypeFilter = typeFilter === '' || machine.type === typeFilter;

		const matchesStatusFilter =
			statusFilter === '' ||
			(statusFilter === 'online' && machine.availableForCustomer) ||
			(statusFilter === 'offline' && !machine.availableForCustomer);

		return matchesSearchFilter && matchesTypeFilter && matchesStatusFilter;
	});

	const searchFilterMachines = async (e) => {
		setSearchFilter(e.target.value);
	};

	const onHandleClearFilters = () => {
		setTypeFilter('');
		setStatusFilter('');
		setSearchFilter('');
	};

	const clearStatusFilter = () => {
		setStatusFilter('');
	};

	const clearTypeFilter = () => {
		setTypeFilter('');
	};

	const handleFilterClick = (filterType, filterValue) => {
		if (filterType === 'status') {
			setStatusFilter(filterValue);
		} else if (filterType === 'type') {
			setTypeFilter(filterValue);
		}
	};

	useEffect(() => {
		setAmountOfResults(filteredMachines.length);
	}, [filteredMachines]);

	/**
	 * ----------------------------------------------------------------
	 * Render Tab Menu
	 * ----------------------------------------------------------------
	 * */

	const items = [
		{ label: 'Machines', icon: 'pi pi-fw pi-home' },
		{
			label: 'Operations screen state',
			icon: 'pi pi-fw pi-cog',
			command: () => {
				window.location = '/machines/state';
			},
		},
	];

	return (
		<div className={`${loading ? 'machine-container-loading' : 'machine-container'}`}>
			<div className={`loading-spinner ${loading ? 'loading-spinner-active' : 'loading-spinner-inactive'}`}>
				<ProgressSpinner strokeWidth="5" style={{ width: '40px', height: '40px' }} stroke="green" />
			</div>

			<div className="machine-sticky">
				<TabMenu model={items} />
			</div>

			<MachineFilter
				amountOfMachinesOffline={amountOfMachinesOffline}
				amountOfMachinesOnline={amountOfMachinesOnline}
				amountOfMachineSmoothie={amountOfMachineSmoothie}
				amountOfMachineSoup={amountOfMachineSoup}
				amountOfMachineCombo={amountOfMachineCombo}
				totalMachines={machines.length}
				onFilterClick={handleFilterClick}
				clearStatusFilter={clearStatusFilter}
				clearTypeFilter={clearTypeFilter}
				statusFilter={statusFilter}
				typeFilter={typeFilter}
			/>

			<ActionBar
				totalMachines={machines.length}
				amountOfResults={amountOfResults}
				statusFilter={statusFilter}
				typeFilter={typeFilter}
				clearActiveFilters={onHandleClearFilters}
				searchFilterValue={searchFilter}
				onhandleSearch={searchFilterMachines}
			/>

			<MachineDataTable filteredMachines={filteredMachines} />
		</div>
	);
};

export default Machine;
