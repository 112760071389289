import React, { useEffect, useState, useMemo } from 'react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Header } from 'semantic-ui-react';
import { Tag } from 'primereact/tag';

import './Ingredient.css';

const api = new ApiService();

function Ingredient() {
	const [all, setAll] = useState([]);
	const [expandedRows, setExpandedRows] = useState({});
	const [machinesForIngredient, setMachinesForIngredient] = useState({});
	const [loadingMachines, setLoadingMachines] = useState(false);
	const [loadingIngredients, setLoadingIngredients] = useState(false);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [amountOfResults, setAmountOfResults] = useState(0);
	const [totalResults, setTotalResults] = useState(0);
	const { authTokens } = useAuth();
	const history = useHistory();

	const headers = useMemo(
		() => ({
			'X-Firebase-Auth-Client': authTokens.token,
		}),
		[authTokens],
	);

	useEffect(() => {
		const fetchIngredients = async () => {
			setLoadingIngredients(true);
			try {
				const response = await api.getAllIngredients(headers);

				// sort alphabetically
				response.data.sort((a, b) => a.name.localeCompare(b.name));

				setAll(response.data);
				setTotalResults(response.data.length);
				setAmountOfResults(response.data.length);
			} catch (error) {
				console.error(error);
			} finally {
				setLoadingIngredients(false);
			}
		};

		fetchIngredients();
	}, [headers]);

	const fetchMachinesForIngredient = async (ingredientId) => {
		setLoadingMachines(true);
		try {
			const response = await api.getMachinesForIngredient(ingredientId, true, headers);
			const machineIds = response.data;

			const machineDetails = await Promise.all(
				machineIds.map(async (machineId) => {
					const machineResponse = await api.getMachine(machineId, headers);
					return machineResponse.data;
				}),
			);

			setMachinesForIngredient((prev) => ({
				...prev,
				[ingredientId]: machineDetails,
			}));
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingMachines(false);
		}
	};

	const editIngredients = (ingredient) => {
		history.push('/ingredient/update', ingredient);
	};

	const editFlavor = (flavor) => {
		history.push('/flavor/update', flavor);
	};

	const onRowToggle = (e) => {
		const newExpandedRows = e.data;
		setExpandedRows(newExpandedRows);

		Object.keys(newExpandedRows).forEach((key) => {
			if (newExpandedRows[key]) {
				const ingredientId = key;
				if (ingredientId && !machinesForIngredient[ingredientId]) {
					fetchMachinesForIngredient(ingredientId);
				}
			}
		});
	};

	const onGlobalFilterChange = (e) => {
		setGlobalFilterValue(e.target.value);
		setFilters({
			...filters,
			global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS },
		});
	};

	const machineTemplate = (rowData) => {
		const ingredientId = rowData.id;
		const machines = machinesForIngredient[ingredientId] || [];

		return (
			<DataTable value={machines} loading={loadingMachines} sortField="name" sortOrder={1} removableSort>
				<Column field="name" header="Machine Name" sortable style={{ width: '10rem', fontWeight: 500 }} />
				<Column field="customerName" header="Customer Name" />
				<Column
					field="Ingredient Availabe"
					header="Ingredient Available"
					style={{ width: '10rem' }}
					body={(machine) => {
						const unavailable = machine.unavailableIngredients.some(
							(unavailableIngredient) => unavailableIngredient.id === ingredientId,
						);

						return (
							<Tag
								className="ingredient-allowed-in-template-tag"
								severity={unavailable ? 'danger' : 'success'}
								icon={unavailable ? 'pi pi-times' : 'pi pi-check'}
								value={unavailable ? 'No' : 'Yes'}
							/>
						);
					}}
				/>
			</DataTable>
		);
	};

	const getSeverity = (type) => {
		switch (type) {
			case 'SMOOTHIE':
				return 'success'; // green
			case 'SOUP':
				return 'warning'; // orange
			default:
				return 'info'; // default color
		}
	};

	const allowedInTemplate = (rowData) => {
		const allowedIn = rowData.flavor.allowedIn;

		return (
			<div>
				{allowedIn.map((type) => (
					<Tag
						key={type}
						value={type.toLowerCase()}
						className="ingredient-allowed-in-template-tag"
						severity={getSeverity(type)}
					/>
				))}
			</div>
		);
	};

	const actionTemplate = (rowData) => {
		return (
			<div className="p-d-flex p-ai-center p-jc-between">
				<Button
					onClick={() => editIngredients(rowData)}
					size="small"
					icon="pi pi-pencil"
					label="Nutrition"
					className="p-button-text p-button-info"
				/>
				<Button
					onClick={() => editFlavor(rowData.flavor)}
					size="small"
					icon="pi pi-pencil"
					label="Flavor"
					className="p-button-text p-button-warning"
				/>
			</div>
		);
	};

	const ingredientTemplate = (rowData) => {
		return (
			<div className="flex align-items-center">
				<div className="ingredient-template-container">
					<img src={rowData.flavor.icon} alt={rowData.name} className="ingredient-template-icon" />
					<div className="ingredient-template-color" style={{ backgroundColor: rowData.flavor.color }}></div>
				</div>
				<span className="ingredient-template-name">{rowData.name}</span>
			</div>
		);
	};

	const maxPercentageTemplate = (rowData) => {
		return (
			<div>
				<span className="ingredient-template-percentage">{rowData.flavor.maxPercentage} %</span>
			</div>
		);
	};

	const header = (
		<div className="flex align-items-center justify-content-between flex-wrap">
			<div className="flex align-items-center justify-content-between gap-2 w-full sm:w-auto flex-wrap">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</span>

				<Button
					size="small"
					text
					label="New Ingr."
					icon="pi pi-plus"
					onClick={() => history.push('/ingredient/create')}
					className="block sm:hidden"
				/>
			</div>

			<div className="action-bar-totals flex align-items-center flex-wrap gap-2 mt-2 md:mt-0">
				<p className="m-0">
					Showing {amountOfResults} of {totalResults} items
				</p>

				<Button
					size="small"
					text
					label="Create New Ingredient"
					icon="pi pi-plus"
					onClick={() => history.push('/ingredient/create')}
					className="hidden sm:block"
				/>
			</div>
		</div>
	);

	const rowClassName = (rowData) => {
		return {
			'highlighted-row': expandedRows[rowData.id] === true,
		};
	};

	const onValueChange = (e) => {
		if (!e.length) {
			return;
		}

		setAmountOfResults(e.length);
	};

	return (
		<>
			<Header as="h2">Ingredients</Header>

			<div className="mt-5 main-datatable">
				<DataTable
					sortField="name"
					sortOrder={1}
					value={all}
					expandedRows={expandedRows}
					onRowToggle={onRowToggle}
					rowExpansionTemplate={machineTemplate}
					header={header}
					dataKey="id"
					removableSort
					size="small"
					globalFilterFields={['name', 'flavor.allowedIn']}
					emptyMessage="No ingredients found"
					filters={filters}
					loading={loadingIngredients}
					showGridlines
					rowClassName={rowClassName}
					onValueChange={onValueChange}
				>
					<Column expander style={{ width: '1rem' }} />
					<Column field="name" header="Ingredient" sortable body={ingredientTemplate} />
					<Column
						field="flavor.maxPercentage"
						header="Max %"
						body={maxPercentageTemplate}
						bodyClassName="text-end"
						style={{ width: '5rem' }}
					/>
					<Column header="Allowed In" body={allowedInTemplate} style={{ width: '8rem' }} />
					<Column header="Actions" body={actionTemplate} style={{ width: '16rem' }} />
				</DataTable>
			</div>
		</>
	);
}

export default Ingredient;
