import { useState, useEffect, useRef } from 'react';
import ApiService from '../../services/ApiService';

const api = new ApiService();

const useMachineLocation = (machineId, authToken) => {
	const [locationHistory, setLocationHistory] = useState([]);
	const [loading, setLoading] = useState(true);
	const fetchedLocationIds = useRef(new Set());

	useEffect(() => {
		const headers = { 'X-Firebase-Auth-Client': authToken };

		const fetchLocationHistory = async () => {
			try {
				const response = await api.getMachineLocationHistory(machineId, headers);

				setLocationHistory(response.data);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchLocationHistory();

		return () => {
			setLocationHistory([]);
		};
	}, [machineId, authToken]);

	useEffect(() => {
		const headers = { 'X-Firebase-Auth-Client': authToken };

		const fetchLocationInfo = async (locationId) => {
			try {
				const response = await api.getLocationById(locationId, headers);
				const locationInfo = response.data;

				setLocationHistory((prevHistory) =>
					prevHistory.map((history) =>
						history.locationId === locationId
							? { ...history, locationName: locationInfo.name || locationInfo.fullAddress }
							: history,
					),
				);
			} catch (error) {
				console.error(error);
			}
		};

		locationHistory.forEach((history) => {
			if (!fetchedLocationIds.current.has(history.locationId)) {
				fetchedLocationIds.current.add(history.locationId);
				fetchLocationInfo(history.locationId);
			}
		});
	}, [locationHistory, authToken]);

	return { locationHistory, loading, setLocationHistory };
};

export default useMachineLocation;
