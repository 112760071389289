import React, { useEffect, useRef, useState } from 'react';
import { PickList } from 'primereact/picklist';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
import { Message } from 'primereact/message';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';

import './MachineRecipesTest.css';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const api = new ApiService();

const MachineRecipesTest = (props) => {
	const { machineId } = props.location.state ? props.location.state : '';
	const toast = useRef(null);
	const history = useHistory();

	const [machineRecipesId, setMachineRecipesId] = useState([]);
	const [machineRecipes, setMachineRecipes] = useState([]);
	const [allRecipes, setAllRecipes] = useState([]);
	const [recipesNotInMachine, setRecipesNotInMachine] = useState([]);
	const [showButton, setShowButton] = useState(false);
	const [ingredientFilterActive, setIngredientFilterActive] = useState(false);
	const [doneLoading, setDoneLoading] = useState(false);

	const [allIncludingInMachine, setAllIncludingInMachine] = useState([]);
	const [machineIngredients, setMachineIngredients] = useState([]);

	const { authTokens } = useAuth();

	const [resetValuesMachineRecipes, setResetValuesMachineRecipes] = useState([]);
	const [resetValuesRecipesNotInMachine, setResetValuesRecipesNotInMachine] = useState([]);

	const headers = {
		'X-Firebase-Auth-Client': authTokens.token,
	};

	useEffect(() => {
		fetchIngredients();
	}, []);

	const fetchIngredients = async () => {
		try {
			const allIngredients = await api.getAllIngredients(headers);
			setAllIncludingInMachine(allIngredients.data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchIngredientsState();
	}, [allIncludingInMachine]);

	const fetchIngredientsState = async () => {
		if (allIncludingInMachine.length === 0) return;
		try {
			const arrayOfIngredientsState = await api.getStateIngredientsInMachine(machineId, headers);

			// this array looks like this
			/**
			 * [
			 * {available: true, enabled: true, compartment: 1, ingredientId: '23702cda-ec65-4f1c-9861-f2f5ee099db0'}
			 * {available: true, enabled: true, compartment: 2, ingredientId: '5414ae69-b4bf-4206-8ec5-f17b54a3b4ac'}
			 * ]
			 */

			// look at the ingredientId and find the ingredient in the all array
			// add the available, enabled, and compartment to the ingredient
			const ingredientsInMachine = arrayOfIngredientsState.data.map((state) => {
				const ingredient = allIncludingInMachine.find((ingredient) => ingredient.id === state.ingredientId);
				if (ingredient) {
					ingredient.available = state.available;
					ingredient.enabled = state.enabled;
					ingredient.compartment = state.compartment;
				}
				return ingredient;
			});

			// sort the ingredients by compartment
			ingredientsInMachine.sort((a, b) => {
				return a.compartment - b.compartment;
			});

			setMachineIngredients(ingredientsInMachine);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const getMachineRecipesByMachineId = async () => {
			const response = await api.getMachineRecipes(machineId, headers);
			setMachineRecipesId(response.data);
		};

		const getAllRecipes = async () => {
			const response = await api.getAllMachineRecipesWithoutPagination(headers);
			setAllRecipes(response.data.recipes);
		};

		getMachineRecipesByMachineId();
		getAllRecipes();
	}, [authTokens.token, machineId]);

	useEffect(() => {
		// find the recipe object for each recipe id and add it to the machineRecipes array and add the index
		const result = machineRecipesId.map((recipe) => {
			const recipeObj = allRecipes.find((r) => r.id === recipe.recipeId);
			return {
				...recipeObj,
				index: recipe.index,
			};
		});

		// sort the machineRecipes array by index
		result.sort((a, b) => a.index - b.index);

		// if recipe does not contain smoothieParts than filter it out
		const recipesWithSmoothieParts = result.filter((recipe) => recipe.smoothieParts);

		setMachineRecipes(recipesWithSmoothieParts);
		setResetValuesMachineRecipes(recipesWithSmoothieParts);

		// find the recipes that are not in the machine
		const recipesNotInMachine = allRecipes.filter((recipe) => {
			return !machineRecipesId.some((machineRecipe) => machineRecipe.recipeId === recipe.id);
		});

		// if ingredients are passed in, filter the recipes that have those ingredients
		if (machineIngredients.length > 0) {
			// return only recipes that contain the ingredients in the machine
			const recipesWithIngredients = recipesNotInMachine.filter((recipe) => {
				const recipeIngredientsName = recipe.smoothieParts.map((smoothiePart) => smoothiePart.flavor.name);
				const machineIngredientsName = machineIngredients.map((ingredient) => ingredient.flavor.name);

				// check if all ingredients in the recipe array are in the machine ingredients array but not in the same order and not all of the machine ingredients are in the recipe array, just look for the ones that are
				return (
					recipeIngredientsName.every((ingredient) => machineIngredientsName.includes(ingredient)) &&
					machineIngredientsName.some((ingredient) => recipeIngredientsName.includes(ingredient))
				);
			});

			// console.log('recipesWithIngredients', recipesWithIngredients);
			setRecipesNotInMachine(recipesWithIngredients);
			setResetValuesRecipesNotInMachine(recipesWithIngredients);
		}
	}, [machineRecipesId, allRecipes, machineIngredients]);

	useEffect(() => {
		if (machineRecipes.length > 0 && !machineRecipes[0].smoothieParts) {
			return;
		}
		// console.log('machineRecipes', machineRecipes);

		// check if ingredinents in machine are the same as the ingredients in the result array
		const ingredientIsNotInMachineAnymore = machineRecipes.map((recipe) => {
			// console.log('recipe', recipe);
			const recipeIngredientsName = recipe.smoothieParts.map((smoothiePart) => smoothiePart.flavor.name);
			const machineIngredientsName = machineIngredients.map((ingredient) => ingredient.flavor.name);

			// check if all ingredients in the recipe array are in the machine ingredients array but not in the same order and not all of the machine ingredients are in the recipe array, just look for the ones that are
			return (
				recipeIngredientsName.every((ingredient) => machineIngredientsName.includes(ingredient)) &&
				machineIngredientsName.some((ingredient) => recipeIngredientsName.includes(ingredient))
			);
		});

		// add a flag to the recipe object if the ingredients are not in the machine anymore
		machineRecipes.forEach((recipe, index) => {
			let newKey = 'valid';
			recipe[newKey] = ingredientIsNotInMachineAnymore[index];
		});

		setMachineRecipes(machineRecipes);
	}, [machineRecipes, machineIngredients, doneLoading]);

	useEffect(() => {
		if (machineRecipes.length > 0 && !machineRecipes[0].smoothieParts) {
			return;
		}

		// get all ingredinets from all recipes in the machine
		const allIngredients = machineRecipes.map((recipe) => recipe.smoothieParts.map((item) => item.flavor.name)).flat();

		// remove duplicates
		const uniqueIngredients = [...new Set(allIngredients)];

		// look at ingredients in machine and remove the ones that are in the machine
		const unusedIngredients = machineIngredients.filter(
			(ingredient) => !uniqueIngredients.includes(ingredient.flavor.name),
		);

		// highlight the ingredient chips that are not in the machine
		const chips = document.querySelectorAll('.ingredient-chip');

		chips.forEach((chip) => {
			if (unusedIngredients.map((ingredient) => ingredient.flavor.name).includes(chip.textContent)) {
				chip.classList.add('ingredient-chip--highlight--error');
			} else {
				chip.classList.remove('ingredient-chip--highlight--error');
			}
		});
	}, [machineRecipes, machineIngredients]);

	useEffect(() => {
		// if machineRecipes fully loaded and has the valid key, set loading to false
		if (machineRecipes.length > 0) {
			// when every recipe has the valid key, set loading to false
			const loading = machineRecipes.every((recipe) => recipe.valid !== undefined);
			console.log('loading', loading);
			if (loading) {
				setDoneLoading(true);
			}
		}
	}, [machineRecipes, machineIngredients]);

	const moveFromMachineToNotInMachine = (event) => {
		// console.log('from in to not ', event);
		// move to not in machine array
		const movedRecipe = event.value[0];

		// remove index from moved recipe
		const movedRecipeWithoutIndex = {
			...movedRecipe,
			index: null,
		};

		// add moved recipe to not in machine array
		setRecipesNotInMachine([...recipesNotInMachine, movedRecipeWithoutIndex]);

		// remove moved recipe from machine array
		const newMachineRecipes = machineRecipes.filter((recipe) => recipe.id !== movedRecipe.id);
		setMachineRecipes(newMachineRecipes);

		// update index of recipes in machine
		const updatedMachineRecipes = newMachineRecipes.map((recipe, index) => {
			return {
				...recipe,
				index: index,
			};
		});

		setMachineRecipes(updatedMachineRecipes);
		// console.log(updatedMachineRecipes);
	};

	const moveFromNotInMachineToMachine = (event) => {
		// console.log('from not to in ', event);
		// move to machine array
		const movedRecipe = event.value[0];

		// add index to moved recipe
		const movedRecipeWithIndex = {
			...movedRecipe,
			index: machineRecipes.length,
		};

		// add moved recipe to machine array
		setMachineRecipes([...machineRecipes, movedRecipeWithIndex]);

		// remove moved recipe from not in machine array
		const newRecipesNotInMachine = recipesNotInMachine.filter((recipe) => recipe.id !== movedRecipe.id);
		setRecipesNotInMachine(newRecipesNotInMachine);

		// console.log(machineRecipes);
	};

	const moveInsideMachine = (event) => {
		setShowButton(true);
		const source = event.source;
		const target = event.target;

		setMachineRecipes(target);
		setRecipesNotInMachine(source);
	};

	const removeAllRecipesFromMachine = () => {
		setRecipesNotInMachine([...recipesNotInMachine, ...machineRecipes]);
		setMachineRecipes([]);
	};

	const addAllRecipesToMachine = () => {
		// console.log('addAllRecipesToMachine');
		setMachineRecipes([...machineRecipes, ...recipesNotInMachine]);
		setRecipesNotInMachine([]);
	};

	const save = () => {
		setShowButton(false);
		const machineRecipesToSave = machineRecipes.map((recipe, index) => {
			return {
				index: index,
				recipeId: recipe.id,
			};
		});

		// console.log('machineRecipes', machineRecipes);
		// console.log('machineRecipesToSave', machineRecipesToSave);

		const saveMachineRecipes = async () => {
			const response = await api.updateMachineRecipes(machineId, machineRecipesToSave, headers);

			if (response.status !== 200) {
				toast.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'Machine Recipes have not been saved',
					life: 3000,
				});
				return;
			} else {
				toast.current.show({
					severity: 'success',
					summary: 'Success',
					detail: 'Machine Recipes have been saved',
					life: 3000,
				});
			}
		};

		saveMachineRecipes();
	};

	const handleHighlightChips = (event) => {
		if (event.value.length === 0) {
			const chips = document.querySelectorAll('.ingredient-chip');
			chips.forEach((chip) => {
				chip.classList.remove('ingredient-chip--highlight');
			});
			return;
		}

		const value = event.value;

		// [{"id":"3725f3f8-2cb9-4a7f-98c4-791516956f4f","name":"Pink Fashion Smoothie","smoothieParts":[{"id":"f58da712-a541-4513-80ef-55f3beb92b82","percentage":40,"flavor":{"id":"ab954b76-bc3d-4cb1-adf3-441c3c64e83a","name":"Strawberry","icon":"https://s3-eu-west-1.amazonaws.com/static.alberts.be/flavors/strawberry.png","behindCupIcon":"https://s3-eu-west-1.amazonaws.com/static.alberts.be/flavors/strawberry_cup.png","maxPercentage":50,"type":"FRUIT","color":"#FF7067","allowedIn":["SMOOTHIE"]}},{"id":"bcbaf3fa-ba08-4e67-b67e-a71513790d81","percentage":20,"flavor":{"id":"e038ff89-79a7-4669-b52b-334ed1a26176","name":"Blueberry","icon":"https://s3-eu-west-1.amazonaws.com/static.alberts.be/flavors/blueberry.png","behindCupIcon":"https://s3-eu-west-1.amazonaws.com/static.alberts.be/flavors/blueberry_cup.png","maxPercentage":30,"type":"FRUIT","color":"#B38FCD","allowedIn":["SMOOTHIE"]}},{"id":"a973cfe0-ce9f-461c-83e9-6bd1badbf508","percentage":40,"flavor":{"id":"4e920b41-28df-4ca2-b145-f08650306c1f","name":"Pineapple","icon":"https://s3-eu-west-1.amazonaws.com/static.alberts.be/flavors/pineapple.png","behindCupIcon":"https://s3-eu-west-1.amazonaws.com/static.alberts.be/flavors/pineapple_cup.png","maxPercentage":50,"type":"FRUIT","color":"#B29706","allowedIn":["SMOOTHIE"]}}],"description":"","favorite":false,"available":true,"image":"https://s3.eu-west-1.amazonaws.com/static.alberts.be/recipes/Pink Fashion-70a12c4a-64c0-4506-b22a-e561c563f172","categories":["SMOOTHIE","ORIGINAL"],"saved":false,"badgeIconUrl":"","waterRatio":0.9}]
		// value looks like this
		// I want to higlight the chips with the ingredients that have the same name as the ingredients in the value array

		const ingredients = value[0].smoothieParts.map((smoothiePart) => smoothiePart.flavor.name);

		const chips = document.querySelectorAll('.ingredient-chip');

		chips.forEach((chip) => {
			if (ingredients.includes(chip.textContent)) {
				chip.classList.add('ingredient-chip--highlight');
			} else {
				chip.classList.remove('ingredient-chip--highlight');
			}
		});
	};

	const handleRecipeFilter = (event) => {
		const ingredient = event.target.textContent;

		// search which recipes contain the ingredient from recipesNotInMachine
		const recipesWithIngredient = resetValuesRecipesNotInMachine.filter((recipe) => {
			const recipeIngredientsName = recipe.smoothieParts.map((smoothiePart) => smoothiePart.flavor.name);
			return recipeIngredientsName.includes(ingredient);
		});

		setRecipesNotInMachine(recipesWithIngredient);
		setIngredientFilterActive(true);
	};

	const handleIngredientFilterActive = () => {
		setRecipesNotInMachine(resetValuesRecipesNotInMachine);
		setIngredientFilterActive(false);
	};

	const editRecipe = async (recipe) => {
		history.push('/recipe/update', recipe);
	};

	const items = [
		{
			label: 'Reset',
			icon: 'pi pi-refresh',
			command: () => {
				// reset values to original
				setShowButton(false);
				setMachineRecipes(resetValuesMachineRecipes);
				setRecipesNotInMachine(resetValuesRecipesNotInMachine);
				toast.current.show({
					severity: 'warn',
					summary: 'Reset',
					detail: 'Machine Recipes have been reset',
					life: 3000,
				});
			},
		},
	];

	// console.log('machineRecipes', machineRecipes);

	return (
		<div>
			<Toast ref={toast}></Toast>

			<div className="ingredients">
				{machineIngredients &&
					machineIngredients.map((ingredient, index) => {
						return (
							<Chip
								onClick={handleRecipeFilter}
								key={index}
								label={ingredient.flavor.name}
								image={ingredient.flavor.icon}
								className="ingredient-chip"
							/>
						);
					})}

				{ingredientFilterActive && (
					<Chip
						icon="pi pi-times"
						onClick={handleIngredientFilterActive}
						label="Clear Filter"
						className="ingredient-chip ingredient-chip--clear-filter"
					/>
				)}
			</div>

			<div className="machine-save-button">
				{showButton && <SplitButton label="Save" icon="pi pi-plus" onClick={save} model={items} />}
			</div>

			<PickList
				source={recipesNotInMachine}
				target={machineRecipes}
				sourceItemTemplate={(item) => (
					<div className="recipe-item">
						{item.badgeIconUrl && (
							<div className="recipe-content-label">
								<img src={item.badgeIconUrl} alt="label" />
							</div>
						)}

						<div className="recipe-item-action">
							<Button
								icon="pi pi-pencil"
								rounded
								severity="secondary"
								aria-label="Edit"
								onClick={() => editRecipe(item)}
							/>
						</div>

						<div className="recipe-item-image">
							<img src={item.image} alt={item.name} />
						</div>

						<div className="recipe-content">
							<div>
								<span className="recipe-content-title">{item.name}</span>

								<div>
									{item.smoothieParts &&
										item.smoothieParts.map((smoothiePart, index) => {
											return (
												<span key={index} className="recipe-content-category">
													{smoothiePart.flavor.name}
												</span>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				)}
				targetItemTemplate={(item) => {
					// console.log('item', item);

					if (item.valid === undefined) {
						setDoneLoading(false);
					} else {
						setDoneLoading(true);
					}

					return (
						<div className={`recipe-item recipe-item--target ${item.valid === false ? 'recipe-item--error' : ''}`}>
							{item.badgeIconUrl && (
								<div className="recipe-content-label">
									<img src={item.badgeIconUrl} alt="label" />
								</div>
							)}

							<div className="recipe-item-action">
								<Button
									icon="pi pi-pencil"
									rounded
									severity="secondary"
									aria-label="Edit"
									onClick={() => editRecipe(item)}
								/>
							</div>

							<div className="recipe-item-image">
								{item.valid === false && (
									<Message className="missing-ingredients" severity="error" text="Ingr. missing" />
								)}
								<img src={item.image} alt={item.name} />
							</div>

							<div className="recipe-content">
								<div>
									<span className="recipe-content-title">{item.name}</span>

									<div>
										{item.smoothieParts &&
											item.smoothieParts.map((smoothiePart, index) => {
												return (
													<span key={index} className="recipe-content-category">
														{smoothiePart.flavor.name}
													</span>
												);
											})}
									</div>
								</div>
							</div>
						</div>
					);
				}}
				sourceHeader="All Recipes"
				targetHeader="Recipes in Machine"
				showSourceControls={false}
				onMoveToSource={moveFromMachineToNotInMachine}
				onMoveToTarget={moveFromNotInMachineToMachine}
				onChange={moveInsideMachine}
				onMoveAllToSource={removeAllRecipesFromMachine}
				onMoveAllToTarget={addAllRecipesToMachine}
				sourceFilterPlaceholder="Search by name"
				targetFilterPlaceholder="Search by name"
				filter
				filterBy="name"
				onSourceSelectionChange={handleHighlightChips}
				onTargetSelectionChange={handleHighlightChips}
			/>
		</div>
	);
};

export default MachineRecipesTest;
