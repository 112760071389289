// filepath: /C:/Software - Alberts/alberts-client-app/src/machineInfo/MachineLocation.jsx
import React, { useRef, useState } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { useAuth } from '../../context/auth';
import useMachineLocation from './MachineLocationHook';
import MachineLocationDialog from './MachineLocationDialog';

import './MachineLocation.css';

const MachineLocation = (props) => {
	const { authTokens, userRole } = useAuth();
	const { locationHistory, loading, setLocationHistory } = useMachineLocation(
		props.location.state.machineId,
		authTokens.token,
	);

	const toast = useRef(null);

	const [dialogActive, setDialogActive] = useState(false);
	const [dialogType, setDialogType] = useState('');

	const onAddLocation = () => {
		setDialogActive(true);
		setDialogType('Add');
	};

	const header = (
		<div className="flex align-items-center justify-content-between">
			<p className="m-0">Location History</p>

			{userRole === 'ADMINISTRATOR' && (
				<Button label="Add Location" icon="pi pi-plus" text size="small" onClick={onAddLocation} />
			)}
		</div>
	);

	return (
		<>
			<Toast ref={toast} />
			<div className="mt-5 main-datatable">
				<DataTable
					value={locationHistory}
					emptyMessage="No location history found"
					tableStyle={{ minWidth: '50rem' }}
					resizableColumns
					loading={loading}
					header={header}
					size="small"
					sortField="startDate"
					sortOrder={-1}
				>
					<Column
						field="locationName"
						header="Location"
						body={(rowData) => rowData.locationName}
						align={'left'}
					></Column>
					<Column
						field="startDate"
						header="Start Date"
						align={'left'}
						style={{ width: '10rem' }}
						body={(rowData) => (rowData.startDate ? moment(rowData.startDate).format('DD-MM-YYYY') : '-')}
					></Column>

					<Column align={'center'} body={(rowData) => <span>→</span>} style={{ width: '2rem' }}></Column>

					<Column
						field="endDate"
						header="End Date"
						align={'right'}
						style={{ width: '10rem' }}
						body={(rowData) => (rowData.endDate ? moment(rowData.endDate).format('DD-MM-YYYY') : '-')}
					></Column>
				</DataTable>
			</div>

			<MachineLocationDialog
				dialogActive={dialogActive}
				setDialogActive={setDialogActive}
				dialogType={dialogType}
				toast={toast}
				machineId={props.location.state.machineId}
				locationHistory={locationHistory}
				setLocationHistory={setLocationHistory}
			/>
		</>
	);
};

export default MachineLocation;
