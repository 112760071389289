import React, { useState, useEffect, useRef } from 'react';
import ApiService from '../services/ApiService';

import { useAuth } from '../context/auth';
import { Toast } from 'primereact/toast';
import { Header } from 'semantic-ui-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

const api = new ApiService();

const Settings = () => {
	const [loading, setLoading] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [operatorDialog, setOperatorDialog] = useState(false);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [email, setEmail] = useState('');
	const [operators, setOperators] = useState([]);

	const toast = useRef(null);

	const { authTokens } = useAuth();
	const headers = {
		'X-Firebase-Auth-Client': authTokens.token,
	};

	const getOperators = async () => {
		try {
			const response = await api.getOperators(headers);
			setOperators(response.data);
		} catch (error) {
			console.error(error);
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to get operators' });
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getOperators();

		return () => {
			setOperators([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async () => {
		setSubmitted(true);
		try {
			// if the user did not fill in the email field, return
			if (email.trim()) {
				await api.addOperator({ email: email }, headers);
				getOperators();
				toast.current.show({ severity: 'success', summary: 'Success', detail: 'Operator added' });

				hideDialog();
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Validation Error',
					detail: 'Please fill in the email field',
				});
			}
		} catch (error) {
			console.error(error);
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add operator' });
		}
	};

	const removeOperator = async (email) => {
		try {
			await api.removeOperator({ email: email }, headers);

			getOperators();

			toast.current.show({ severity: 'success', summary: 'Success', detail: 'Operator removed' });
		} catch (error) {
			console.error(error);
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to remove operator' });
		}
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const openNew = () => {
		setEmail('');
		setOperatorDialog(true);
		setSubmitted(false);
	};

	const hideDialog = () => {
		setOperatorDialog(false);
		setSubmitted(false);
	};

	const header = (
		<div className="flex justify-content-between">
			<div className="flex justify-content-end">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</span>
			</div>

			<Button className="ml-3" label="Invite new operator" icon="pi pi-plus" text onClick={openNew} />
		</div>
	);

	const operatorDialogFooter = (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				outlined
				onClick={() => {
					hideDialog();
					toast.current.show({ severity: 'info', summary: 'Info', detail: 'Adding operator cancelled' });
				}}
			/>
			<Button label="Save" icon="pi pi-check" onClick={handleSubmit} />
		</div>
	);

	return (
		<>
			<Header as="h2">Operators</Header>
			<Toast ref={toast} />

			<div className="mt-5 main-datatable">
				<DataTable
					value={operators}
					loading={loading}
					tableStyle={{ minWidth: '20rem' }}
					paginator
					rows={10}
					alwaysShowPaginator={false}
					size="small"
					header={header}
					filters={filters}
					globalFilterFields={['login']}
				>
					<Column field="login" header="Email"></Column>
					<Column
						field="role"
						header="Role"
						body={(rowData) => (
							<Tag
								className="recipes-template-categories"
								value={rowData.role}
								severity={rowData.role === 'ADMINISTRATOR' ? 'success' : 'secondary'}
							/>
						)}
						style={{ width: '5rem' }}
					></Column>
					<Column
						header="Action"
						align={'right'}
						body={(rowData) => (
							<Button icon="pi pi-trash" text severity="danger" onClick={() => removeOperator(rowData.login)} />
						)}
					></Column>
				</DataTable>
			</div>

			<Dialog
				visible={operatorDialog}
				style={{ width: '450px' }}
				header="Invite Operator"
				modal
				className="p-fluid"
				onHide={hideDialog}
				footer={operatorDialogFooter}
			>
				<div className="p-field my-4">
					<label htmlFor="email">Email</label>
					<InputText
						id="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="placeholder@alberts.be"
						required
						type="email"
						className={classNames({ 'p-invalid': submitted && !email })}
					/>
					{submitted && !email && <small className="p-error">Email is required.</small>}
				</div>
			</Dialog>
		</>
	);
};

export default Settings;
