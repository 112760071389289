import React, { useState } from 'react';
import { Form, Button, Grid, Input, Image, Icon, GridRow, Message } from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import { ChromePicker } from 'react-color';
import { useHistory } from 'react-router-dom';
const api = new ApiService();

const FlavorCreator = (props) => {
	const allowedInOptions = [
		{ key: 'smoothie', value: 'SMOOTHIE', text: 'Smoothie' },
		{ key: 'soup', value: 'SOUP', text: 'Soup' },
	];
	const [type, setType] = useState(props.location.state ? props.location.state.type : '');
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [displayIcon, setDisplayIcon] = useState(
		props.location.state ? (props.location.state.icon ? true : false) : false,
	);
	const [icon, setIcon] = useState(props.location.state ? props.location.state.icon : '');
	const [name, setName] = useState(props.location.state ? props.location.state.name : '');
	const [maxPercentage, setMaxPercentage] = useState(
		props.location.state.maxPercentage ? props.location.state.maxPercentage : undefined,
	);
	const [displayBehindCupIcon, setDisplayBehindCupIcon] = useState(
		props.location.state ? (props.location.state.behindCupIcon ? true : false) : false,
	);
	const [behindCupIcon, setBehindCupIcon] = useState(
		props.location.state.behindCupIcon ? props.location.state.behindCupIcon : '',
	);
	const [color, setColor] = useState(props.location.state.color ? props.location.state.color : '#000000');
	const [iconBlob, setIconBlob] = useState();
	const [behindCupIconBlob, setBehindCupIconBlob] = useState();
	const [allowedIn, setAllowedIn] = useState(props.location.state ? props.location.state.allowedIn : []);
	const [maxLengthError, setMaxLengthError] = useState(false);
	const { authTokens } = useAuth();
	const history = useHistory();
	const headers = {
		'X-Firebase-Auth-Client': authTokens.token,
	};

	const handleTypeChange = (e, { value }) => {
		setType(value);
	};

	const onChangeDropDownAllowedIn = (e, { value }) => {
		setAllowedIn(value);
	};

	const openColorPicker = () => {
		setDisplayColorPicker(!displayColorPicker);
	};

	const closeColorPicker = () => {
		setDisplayColorPicker(false);
	};

	const changeColor = (color) => {
		setColor(color.hex);
	};

	const iconChange = (e) => {
		setDisplayIcon(true);
		setIcon(URL.createObjectURL(e.target.files[0]));
		setIconBlob(e.target.files[0]);
	};

	const iconBehindCupChange = (e) => {
		setDisplayBehindCupIcon(true);
		setBehindCupIcon(URL.createObjectURL(e.target.files[0]));
		setBehindCupIconBlob(e.target.files[0]);
	};

	const removeIconBehindCup = () => {
		setDisplayBehindCupIcon(false);
		setBehindCupIcon();
	};
	const removeIcon = () => {
		setDisplayIcon(false);
		setIcon();
	};

	const handleSubmit = async () => {
		setLoading(true);

		// if character length is greater than 16, set error to true and cancel submit
		if (name?.length > 16) {
			setMaxLengthError(true);
			setError(true);
			setLoading(false);
			return;
		}

		let awsIcon = icon;
		let awsBehindCupIcon = behindCupIcon;
		if (iconBlob) {
			awsIcon = await uploadImages(iconBlob);
		}
		if (behindCupIconBlob) {
			awsBehindCupIcon = await uploadImages(behindCupIconBlob);
		}

		try {
			if (props.location.state.id !== undefined) {
				const flavorId = props.location.state.id;
				await api.updateFlavor(
					flavorId,
					{ name, icon: awsIcon, behindCupIcon: awsBehindCupIcon, maxPercentage, type, color, allowedIn },
					headers,
				);
			} else {
				console.log(allowedIn);
				await api.createFlavors(
					{ name, icon: awsIcon, behindCupIcon: awsBehindCupIcon, maxPercentage, type, color, allowedIn },
					headers,
				);
				history.push('/ingredient/create');
			}
			setLoading(false);
			setSuccess(true);
		} catch (error) {
			setLoading(false);
			setError(true);
		}
	};

	const uploadImages = async (data) => {
		const formData = new FormData();
		formData.append('file', data);
		formData.append('name', name);
		formData.append('type', 'flavors');
		const result = await api.uploadFile(formData, headers);
		return result.data;
	};

	const validateMaxLength = (e) => {
		setName(e.target.value);
		if (e.target.value.length > 16) {
			setMaxLengthError(true);
		} else {
			setMaxLengthError(false);
		}
	};

	return (
		<Grid padded>
			<Form onSubmit={handleSubmit} success={success} loading={loading} error={error}>
				<Form.Field required>
					<label>Flavor name</label>
					<Input placeholder="flavor name" value={name} onChange={(e) => validateMaxLength(e)} />
					{maxLengthError && (
						<div className="ui pointing red basic label">
							<Icon name="exclamation triangle" />
							Flavor name must be less than 16 characters
						</div>
					)}
				</Form.Field>
				<Form.Group widths="equal">
					<Form.Field>
						<label>Icon</label>
						{displayIcon ? (
							<Grid padded columns={1}>
								<GridRow>
									<Icon onClick={removeIcon} name="delete" />{' '}
								</GridRow>
								<GridRow>
									<Image src={icon} size="small" />
								</GridRow>
							</Grid>
						) : (
							<Input type="file" onChange={iconChange} />
						)}
					</Form.Field>
					<Form.Field>
						<label>Icon behind cup</label>
						{displayBehindCupIcon ? (
							<Grid padded columns={1}>
								<GridRow padded>
									<Icon onClick={removeIconBehindCup} name="delete" />{' '}
								</GridRow>
								<GridRow padded>
									<Image src={behindCupIcon} size="small" />
								</GridRow>
							</Grid>
						) : (
							<Input type="file" onChange={iconBehindCupChange} />
						)}
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field required>
						<label>Type</label>
						<Form.Radio label="Fruit" value="FRUIT" checked={type === 'FRUIT'} onChange={handleTypeChange} />
						<Form.Radio label="Vegetable" value="VEGGIE" checked={type === 'VEGGIE'} onChange={handleTypeChange} />
						<Form.Radio label="Booster" value="BOOSTER" checked={type === 'BOOSTER'} onChange={handleTypeChange} />
					</Form.Field>
				</Form.Group>
				<Form.Group widths="equal">
					<Form.Field required>
						<label>Maximum percentage of flavor</label>
						<Input
							type="number"
							label={{ basic: true, content: '%' }}
							labelPosition="right"
							placeholder="Maximum percentage of flavor"
							value={maxPercentage}
							onChange={(e) => setMaxPercentage(e.target.value)}
						/>
					</Form.Field>
					<Form.Field width={6}>
						<label>color</label>
						<div
							fluid
							style={{ backgroundColor: color, width: '100%', height: '38px', borderRadius: '2px' }}
							onClick={openColorPicker}
						></div>
						{displayColorPicker ? (
							<div style={{ position: 'absolute', zIndex: '2' }}>
								<div
									style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
									onClick={closeColorPicker}
								/>
								<ChromePicker disableAlpha={true} color={color} onChange={changeColor} />
							</div>
						) : null}
					</Form.Field>
				</Form.Group>
				<Form.Dropdown
					label="Allowed in"
					placeholder="Allowed in"
					search
					selection
					multiple
					options={allowedInOptions}
					onChange={onChangeDropDownAllowedIn}
					value={allowedIn}
				></Form.Dropdown>
				<Message success header="Completed" content="New flavor added" />
				<Message error header="Failed" content="Failed to add new flavor" />
				<Button color="green" basic type="submit">
					Submit
				</Button>
			</Form>
		</Grid>
	);
};

export default FlavorCreator;
